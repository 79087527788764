<template>
    <v-form ref="form">
        <h1 class="text-h1 font-weight-600 text-black mb-2 pt-5">First, let’s setup your account.</h1>
        <p class="text-black font-size-root font-weight-thin mb-8">Welcome to GoDigy! To set up your admin account, enter your details.</p>
        <v-row class="uploader-row">
            <v-col cols="5">
                <Uploader v-model="userCopy.avatar" width="100%" :accepts="['image/png', 'image/jpeg', 'image/jpg', 'image/webp']" :maxSize="4194304" :context="'avatar'" />
                <!-- <Uploader type="single" v-model="fileUpload" :isUploading="false" :accepts="['image/jpeg', 'image/gif', 'image/webp', 'image/png']" :maxSize="1048576"/> -->
            </v-col>
            <v-col cols="7">
                <p class="subtitle-2 text-black ma-0">Upload an avatar to easily identify you through the platform. If none is supplied, your initials will be used</p>
            </v-col>
        </v-row>
        <label for="firstName" class="label-color font-weight-600 subtitle-2 mb-2 d-block">First Name</label>
        <v-text-field
            :rules="[rules.required]"
            id="firstName"
            autocomplete="off"
            hide-details="auto"
            validate-on-blur
            v-model="userCopy.first_name"
            outlined
            class="input-style font-size-input text-color-gray mt-0 mb-4"
            placeholder="John">
        </v-text-field>
        <label for="lastName" class="label-color font-weight-600 subtitle-2 mb-2 d-block">Last Name</label>
        <v-text-field
            :rules="[rules.required]"
            id="lastName"
            autocomplete="off"
            hide-details="auto"
            validate-on-blur
            v-model="userCopy.last_name"
            outlined
            class="input-style font-size-input text-color-gray mt-0 mb-4"
            placeholder="Doe">
        </v-text-field>
        <label for="email" class="label-color font-weight-600 subtitle-2 mb-2 d-block">Email</label>
        <v-text-field
            :rules="[rules.required, rules.email]"
            autocomplete="off"
            hide-details="auto"
            validate-on-blur
            id="email"
            v-model="userCopy.email"
            outlined
            class="input-style font-size-input text-color-gray mt-0 mb-4"
            placeholder="johndoe@gmail.com">
        </v-text-field>
        <label for="password" class="label-color font-weight-600 subtitle-2 mb-2 d-block">Password</label>
        <v-text-field
            :rules="[rules.required, rules.password]"
            id="password"
            v-model="userCopy.password"
            hide-details="auto"
            validate-on-blur
            outlined
            autocomplete="off"
            class="input-style font-size-input text-color-gray mt-0 mb-4"
            placeholder="********"
            :type="showPassword ? 'text' : 'password'">
            <template slot="append">
                <feather :type="showPassword ? 'eye' : 'eye-off'" size=".875rem" stroke-width="1.5" color="#adb5bd" @click="showPassword = !showPassword" class="eye"> </feather>
            </template>
        </v-text-field>
        <v-row>
            <v-col cols="6" md="6">
                <v-btn @click="$emit('cancel')" elevation="0" block height="43" class="font-weight-600 text-capitalize btn-secondary py-3 px-6 rounded-sm mt-3" color="#f7fafc"> Cancel </v-btn>
            </v-col>
            <v-col cols="6" md="6">
                <v-btn @click="next()" elevation="0" block height="43" class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm mt-3" color="#6F70FF"> Continue </v-btn>
            </v-col>
        </v-row>
    </v-form>
</template>
<script>
import { mapActions } from 'vuex';
import Uploader from '@/components/UploaderV2/Uploader.vue';
export default {
    name: 'account-information',
    props: {
        user: {
            type: Object,
            default: () => ({})
        }
    },
    components: {
        Uploader
    },
    data() {
        return {
            showPassword: false,
            userCopy: this.user,
            rules: {
                required: (value) => (value && Boolean(value)) || '*This field is required',
                email: (value) => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

                    return pattern.test(value) || 'Invalid e-mail.';
                },
                password: (value) => {

                    let atLeast10Chars = value.length >= 10;
                    let matchRestPattern = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])((?=.*\W)|(?=.*_))^[^ ]+$/g;
                    let matchRest = matchRestPattern.test(value);

                    return (atLeast10Chars && matchRest) || 'Password must be at least 10 characters, with lower- and uppercase letters, at least one number, and a special character';

                    // const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$/;
                    // return pattern.test(value) || 'Password must be at least 10 characters, with lower- and uppercase letters, at least one number, and a special character';
                }
            }
        };
    },
    computed: {
        initials() {
            if (!this.userCopy.name) return 'N';

            const initials = (this.userCopy.first_name + ' ' + this.userCopy.last_name).match(/\b\w/g) || [];

            return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
        }
    },
    methods: {
        next() {
            if (this.$refs.form.validate()) {
                this.$emit('next');
            }
        },
        ...mapActions('app', ['showSuccess', 'showError'])
    }
};
</script>

<style lang="scss">
.eye {
    &:hover {
        cursor: pointer;
    }
}
.uploader-row {
    margin-bottom: 20px;
}
</style>
