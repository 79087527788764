@@ -1,95 +0,0 @@
<template>
    <v-form ref="form">
        <h1 class="text-h1 font-weight-600 text-black mb-2 pt-5">Organisation Information</h1>
        <p class="text-black font-size-root font-weight-thin mb-8">The organisation’s information is important to establish clear brand guidelines for forms and documents.</p>
        <label for="orgName" class="label-color font-weight-600 subtitle-2 mb-2 d-block">Organisation Name</label>
        <v-text-field
            :rules="[rules.required]"
            id="orgName"
            autocomplete="off"
            hide-details="auto"
            validate-on-blur
            v-model="organisationCopy.name"
            outlined
            class="input-style font-size-input text-color-gray mt-0 mb-4"
            placeholder="Company or department">
        </v-text-field>
        <label for="orgRegistrationNum" class="label-color font-weight-600 subtitle-2 mb-2 d-block">Registration Number (Optional)</label>
        <v-text-field
            id="orgRegistrationNum"
            autocomplete="off"
            hide-details="auto"
            v-model="organisationCopy.regNum"
            outlined
            class="input-style font-size-input text-color-gray mt-0 mb-4"
            placeholder="YYYY/NNNNNN/NN">
        </v-text-field>
        <label for="orgColour" class="label-color font-weight-600 subtitle-2 mb-2 d-block">Accent Colour</label>
        <v-text-field
            :rules="[rules.required]"
            v-model="colorValue"
            validate-on-blur
            id="orgColour"
            hide-details="auto"
            outlined
            class="input-style font-size-input text-color-gray mt-0 mb-6"
            placeholder="#805CFE">
            <template v-slot:append>
                <v-menu v-model="menu" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                    <template v-slot:activator="{ on }">
                        <div :style="swatchStyle" v-on="on" />
                    </template>
                    <v-card>
                        <v-card-text class="pa-0">
                            <v-color-picker v-model="colorValue" flat />
                        </v-card-text>
                    </v-card>
                </v-menu>
            </template>
        </v-text-field>
        <v-row>
            <v-col cols="5">
                <Uploader v-model="organisationCopy.logo" width="100%" :accepts="['image/png', 'image/jpeg', 'image/jpg', 'image/webp']" :maxSize="4194304" :context="'logo'" />
            </v-col>
            <v-col cols="7">
                <p class="subtitle-2 text-black ma-0">Upload your company logo. Your logo will display on all generated reports/documents</p>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6" md="6">
                <v-btn @click="$emit('prev')" elevation="0" block height="43" class="font-weight-600 text-capitalize btn-secondary py-3 px-6 rounded-sm mt-3" color="#f7fafc"> Back </v-btn>
            </v-col>
            <v-col cols="6" md="6">
                <v-btn @click="next()" elevation="0" block height="43" class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm mt-3" color="#6F70FF"> Continue </v-btn>
            </v-col>
        </v-row>
    </v-form>
</template>
<script>
import Uploader from '@/components/UploaderV2/Uploader.vue';
import { mapActions } from 'vuex';
export default {
    name: 'organisation-information',
    props: {
        organisation: {
            type: Object,
            default: () => ({})
        }
    },
    components: {
        Uploader
    },
    data() {
        return {
            menu: false,
            organisationCopy: this.organisation,
            rules: {
                required: (value) => (value && Boolean(value)) || '*This field is required'
            }
        };
    },
    methods: {
        changeColour(value) {
            this.organisationCopy.accentColour = value;
        },
        next() {
            if (this.$refs.form.validate()) {
                this.$emit('next');
            }
        },
        ...mapActions('app', ['showSuccess', 'showError'])
    },
    computed: {
        swatchStyle() {
            return {
                backgroundColor: this.colorValue,
                cursor: 'pointer',
                height: '15px',
                width: '15px',
                borderRadius: '100%',
                transition: 'border-radius 200ms ease-in-out'
            };
        },
        colorValue: {
            get() {
                if (!this.organisationCopy.accentColour) {
                    this.changeColour('#6F70FF');

                    return '#6F70FF';
                } else {
                    return this.organisationCopy.accentColour;
                }
            },
            set(value) {
                this.changeColour(value);
            }
        },
        initials() {
            if (!this.organisationCopy.name) return 'N';

            const initials = this.organisationCopy.name.match(/\b\w/g) || [];

            return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
        }
    }
};
</script>
