@@ -1,95 +0,0 @@
<template>
    <v-form ref="form">
        <h1 class="text-h1 font-weight-600 text-black mb-2 pt-5">Licence Configuration</h1>
        <p class="text-black font-size-root font-weight-thin mb-8">In order to set up the software licence for the account, the hosting provider’s information needs to be entered.</p>
        <label for="licenceName" class="label-color font-weight-600 subtitle-2 mb-2 d-block">Licence Name</label>
        <v-text-field
            :rules="[rules.required]"
            id="licenceName"
            autocomplete="off"
            hide-details="auto"
            validate-on-blur
            v-model="licenceCopy.name"
            outlined
            class="input-style font-size-input text-color-gray mt-0 mb-4"
            placeholder="Name">
        </v-text-field>
        <label for="hostingProvider" class="label-color font-weight-600 subtitle-2 mb-2 d-block">Hosting Provider</label>
        <v-text-field
            :rules="[rules.required]"
            id="hostingProvider"
            autocomplete="off"
            hide-details="auto"
            validate-on-blur
            v-model="licenceCopy.hostingProvider"
            outlined
            class="input-style font-size-input text-color-gray mt-0 mb-4"
            placeholder="Digital Ocean">
        </v-text-field>
        <p class="text-subtitle-2 pa-0 ma-0 pb-1 font-weight-bold">Service Connection</p>
        <v-divider class="py-2"></v-divider>
        <v-row>
            <v-col cols="6">
                <label for="license_host" class="label-color font-weight-600 subtitle-2 mb-2 d-block">Host</label>
                <v-text-field
                    :rules="[rules.required]"
                    id="license_host"
                    autocomplete="off"
                    hide-details="auto"
                    validate-on-blur
                    v-model="licenceCopy.licencingServiceConnection.host"
                    outlined
                    class="input-style font-size-input text-color-gray mt-0 mb-4"
                    placeholder="localhost">
                </v-text-field>
            </v-col>
            <v-col cols="6">
                <label for="license_port" class="label-color font-weight-600 subtitle-2 mb-2 d-block">Port</label>
                <v-text-field
                    :rules="[rules.required, rules.port]"
                    id="license_port"
                    autocomplete="off"
                    hide-details="auto"
                    validate-on-blur
                    v-model="licenceCopy.licencingServiceConnection.port"
                    outlined
                    class="input-style font-size-input text-color-gray mt-0 mb-4"
                    placeholder="80"
                    type="number">
                </v-text-field>
            </v-col>
        </v-row>
        <v-switch v-model="licenceCopy.licencingServiceConnection.secure" inset hide-details label="Secure" color="#6F70FF" class="switch mb-6 mt-0 pl-1"> </v-switch>
        <p class="text-subtitle-2 pa-0 ma-0 pb-1 font-weight-bold">Instance Connection</p>
        <v-divider class="py-2"></v-divider>
        <v-row>
            <v-col cols="6">
                <label for="instance_host" class="label-color font-weight-600 subtitle-2 mb-2 d-block">Host</label>
                <v-text-field
                    :rules="[rules.required]"
                    id="instance_host"
                    autocomplete="off"
                    hide-details="auto"
                    validate-on-blur
                    v-model="licenceCopy.instanceConnection.host"
                    outlined
                    class="input-style font-size-input text-color-gray mt-0 mb-4"
                    placeholder="localhost">
                </v-text-field>
            </v-col>
            <v-col cols="6">
                <label for="instance_port" class="label-color font-weight-600 subtitle-2 mb-2 d-block">Port</label>
                <v-text-field
                    :rules="[rules.required, rules.port]"
                    id="instance_port"
                    autocomplete="off"
                    hide-details="auto"
                    validate-on-blur
                    v-model="licenceCopy.instanceConnection.port"
                    outlined
                    class="input-style font-size-input text-color-gray mt-0 mb-4"
                    placeholder="80"
                    type="number">
                </v-text-field>
            </v-col>
        </v-row>
        <v-switch v-model="licenceCopy.instanceConnection.secure" inset hide-details label="Secure" color="#6F70FF" class="switch mb-6 mt-0 pl-1"> </v-switch>
        <v-btn
            :loading="isTestingConnection"
            :disabled="isTestingConnection"
            @click="testConnection()"
            elevation="0"
            block
            height="43"
            class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm mt-3"
            color="#6F70FF">
            Test Connection
        </v-btn>
        <v-row>
            <v-col cols="6" md="6">
                <v-btn @click="$emit('prev')" elevation="0" block height="43" class="font-weight-600 text-capitalize btn-secondary py-3 px-6 rounded-sm mt-3" color="#f7fafc"> Back </v-btn>
            </v-col>
            <v-col cols="6" md="6">
                <v-btn
                    @click="complete()"
                    elevation="0"
                    block
                    height="43"
                    class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm mt-3"
                    color="#6F70FF"
                    :title="!didConnectionSucceed ? 'Connection must succeed before continuing' : ''"
                    :disabled="isBusy || !didConnectionSucceed"
                    :loading="isBusy">
                    Create Account
                </v-btn>
            </v-col>
        </v-row>
    </v-form>
</template>
<script>
import axios from 'axios';
import { mapActions } from 'vuex';
export default {
    name: 'licence-configuration',
    props: {
        licence: {
            type: Object,
            default: () => ({
                name: '',
                hostingProvider: '',
                licencingServiceConnection: {
                    host: '',
                    port: -1,
                    secure: false,
                    interval: '',
                    instance_id: null
                },
                instanceConnection: {
                    host: '',
                    port: -1,
                    secure: false
                },
                disabled: false
            })
        },
        isBusy: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            licenceCopy: this.licence,
            rules: {
                required: (value) => (value && Boolean(value)) || '*This field is required',
                port: (value) => value > 0 || '*Port must be larger than 0'
            },
            didConnectionSucceed: false,
            isTestingConnection: false
        };
    },
    methods: {
        complete() {
            if (this.$refs.form.validate() && this.didConnectionSucceed) {
                this.$emit('complete');
            }
        },
        async testConnection() {
            // do something
            if (this.$refs.form.validate()) {
                this.didConnectionSucceed = false;
                try {
                    this.isTestingConnection = true;
                    if (process.env.VUE_APP_BYPASS_LICENCING) {
                        this.showSuccess('Connection succeeded. Licencing service disabled');
                        this.didConnectionSucceed = true;
                        this.isTestingConnection = false;
                        return;
                    }

                    let response = await this.$store.dispatch('licencing-communicator/find', {
                        query: {
                            secure: this.licenceCopy.licencingServiceConnection.secure,
                            host: this.licenceCopy.licencingServiceConnection.host,
                            port: this.licenceCopy.licencingServiceConnection.port
                        }
                    });
                    if (response[0]?.success) {
                        this.showSuccess('Connection succeeded');
                        this.didConnectionSucceed = true;
                        this.isTestingConnection = false;
                        this.licenceCopy.licencingServiceConnection.interval = response[0].interval;
                    } else throw new Error('Failed to connect to licencing service');
                } catch (error) {
                    this.showError(error);
                    this.didConnectionSucceed = false;
                    this.isTestingConnection = false;
                }
            }
        },
        ...mapActions('app', ['showSuccess', 'showError'])
    }
};
</script>