<template>
    <v-row class="d-flex justify-center">
        <v-col cols="12" sm="8" md="8" lg="6" xl="4">
            <v-card class="xs-margin xs-padding sm-padding card-shadow bg-white">
                <v-stepper v-model="stepNum">
                    <v-stepper-header>
                        <v-stepper-step step="1"></v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="2"></v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="3"></v-stepper-step>
                    </v-stepper-header>
                    <v-stepper-content step="1">
                        <account-information :user.sync="user" @cancel="cancelAccountCreation()" @next="nextStep(1)"></account-information>
                    </v-stepper-content>
                    <v-stepper-content step="2">
                        <organisation-information :organisation.sync="config.organisation" @prev="nextStep(0)" @next="nextStep(2)"></organisation-information>
                    </v-stepper-content>
                    <v-stepper-content step="3">
                        <licence-configuration :licence.sync="config.licence" @prev="nextStep(1)" @complete="createAccount()" :isBusy="isBusy"></licence-configuration>
                    </v-stepper-content>
                </v-stepper>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import { models } from 'feathers-vuex';
import AccountInformation from './AccountInformation';
import OrganisationInformation from './OrganisationInformation';
import LicenceConfiguration from './LicenceConfiguration';
import { mapActions } from 'vuex';

export default {
    name: 'Onboarding',
    components: {
        AccountInformation,
        OrganisationInformation,
        LicenceConfiguration
    },
    data() {
        return {
            stepNum: 1,
            steps: 3,
            user: null,
            isBusy: false
        };
    },
    async created() {
        const { Config, User } = models.api;
        this.config = new Config();
        this.config.licence = {
            name: '',
            hostingProvider: '',
            licencingServiceConnection: {
                host: '',
                port: -1,
                secure: false,
                interval: '',
                instance_id: null
            },
            instanceConnection: {
                host: '',
                port: -1,
                secure: false
            },
            disabled: false
        };

        const url = document.createElement('a');
        url.setAttribute('href', window.location.href);
        this.config.licence.instanceConnection.host = url.hostname;
        this.config.licence.instanceConnection.port = url.port;
        this.config.licence.instanceConnection.secure = false;
        if (url.protocol === 'http:') {
            this.config.licence.instanceConnection.secure = false;
        } else if (url.protocol === 'https:') {
            this.config.licence.instanceConnection.secure = true;
        }

        this.user = new User();

        this.user.system = true;
        this.user.role = 'Admin';
    },
    methods: {
        nextStep(n) {
            if (n === this.steps) {
                this.stepNum = 1;
            } else {
                this.stepNum = n + 1;
            }
            console.log(this.user);
        },
        async createAccount() {
            try {
                this.isBusy = true;
                await this.config.save();
                await this.user.save();

                if (this.user.avatar) this.user.avatar.userId = this.user.id;

                if (!process.env.VUE_APP_BYPASS_LICENCING) {
                    await this.$store.dispatch('licencing-communicator/create', {
                        connection: {
                            host: this.config.licence.licencingServiceConnection.host,
                            port: this.config.licence.licencingServiceConnection.port,
                            secure: this.config.licence.licencingServiceConnection.secure
                        },
                        licencingInformation: {
                            host: this.config.licence.instanceConnection.host,
                            port: this.config.licence.instanceConnection.port,
                            secure: this.config.licence.instanceConnection.secure,
                            name: this.config.licence.name,
                            hosting_provider: this.config.licence.hostingProvider
                        }
                    });
                }

                this.showSuccess('Successfully created a new account!');
                this.$router.push({
                    name: 'setup-complete',
                    params: {
                        email: this.user.email,
                        orgName: this.config.organisation.name
                    }
                });
                this.isBusy = false;
            } catch (error) {
                this.user.remove();
                this.config.remove();
                this.$forceUpdate();
                this.showError(error);
                this.isBusy = false;
            }
        },
        cancelAccountCreation() {
            this.$router.push({ name: 'create-account' });
        },
        ...mapActions('app', ['showSuccess', 'showError'])
    }
};
</script>

<style lang="scss">
.v-stepper {
    background: none !important;
    box-shadow: none !important;
}

.v-stepper__header {
    box-shadow: none !important;
}

.v-stepper__step__step.primary {
    background-color: #6f70ff !important;
    border-color: #6f70ff !important;
    color: white !important;
}

.v-stepper__step__step {
    background-color: white !important;
    border: 1px solid #e9ecef !important;
    color: #8898aa !important;
}

@media screen and (max-width: 599px) {
    .xs-margin {
        margin: 0 10px;
    }

    .xs-padding {
        padding: 10px;
    }
}

@media screen and (min-width: 600px) {
    .sm-padding {
        padding: 40px;
    }
}
</style>
